* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif; /* Changed font family */
}

body {
    color: #000; /* Preserve body text color */
    overflow-x: hidden;
    min-height: 100%; /* Changed height to min-height */
    background-repeat: no-repeat;
    /* background-image: url('./images/comoditahome2.png'); Replace 'path/to/your/image.jpg' with the actual path to your image */
}

.card {
    z-index: 0;
    background-color: #ECEFF1;
    padding-bottom: 20px;
    /* margin-top: 40px; */
    /* margin-bottom: 90px; */
    border-radius: 10px;
}

.top {
    padding-top: 40px;
    padding-left: 13% !important;
    padding-right: 13% !important;
}






/* Navbar Styles */
.navbar {
    background-color: #fff;
}

.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
}

.navbar-icon {
    width: 200px;
    height: auto;
}



.navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
    color: #fff;
}

.dropdown-menu {
    background-color: #343a40;
}

.dropdown-item {
    color: #fff;
}

.dropdown-item:hover {
    background-color: #6c757d;
}

.form-control {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1);
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.form-control:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.btn-outline-light {
    color: #fff;
    border-color: #fff;
}

.btn-outline-light:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.slide-in {
    animation: slideIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    opacity: 0;
    transform: translateY(-100%);
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}




/*Icon progressbar*/
#progressbar {
    margin-bottom: 10px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 20px;
} 

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 33%;
    float: left;
    position: relative;
    font-weight: 400;
}

#progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f10c";
    animation: pulse 1.5s infinite;
}


#progressbar .step0:before {
    font-family: FontAwesome; 
    font-weight: 900;
    content: "\f10c"; 
    color: #fff;
}


/* Default styles for screens smaller than 767px */
#progressbar li:before {
    width: 30px;
    height: 30px;
    line-height: 45px;
    display: block;
    font-size: 0px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px;
}

/* ProgressBar connectors for screens larger than 767px */
#progressbar li:after {
    content: '';
    width: 100%;
    height: 8px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 12px;
    z-index: -1;
}

/* Styles for screens larger than 767px */
@media (min-width: 768px) {
    #progressbar li:before {
        width: 40px;
        height: 40px;
        line-height: 45px;
        display: block;
        font-size: 20px;
        background: #C5CAE9;
        border-radius: 50%;
        margin: auto;
        padding: 0px;
    }
    
    /* ProgressBar connectors for screens smaller than 767px */
    #progressbar li:after {
        content: '';
        width: 100%;
        height: 12px;
        background: #C5CAE9;
        position: absolute;
        left: 0;
        top: 16px;
        z-index: -1;
    }
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%;
}

#progressbar li:nth-child(2):after, #progressbar li:nth-child(3):after {
    left: -50%;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before, #progressbar li.active:after {
    background: #651FFF;
}



/*Color number of the step and the connector before it for Dorezuar status*/
#progressbar li.active.dorezuar:before,
#progressbar li.active.dorezuar:after {
    /* background: #4CAF50; Green color for Dorezuar status */
    background: #651FFF;
}

#progressbar li.active.dorezuar:before {
    content: "\f00c"; /* Checked icon for Dorezuar status */
    font-family: FontAwesome;
    animation: pulse 1.5s infinite;
}

.refuzuar {
    background-color: red;
}






@keyframes pulse {
    0% {
        transform: scale(0.8); /* Start with a smaller size */
    }
    50% {
        transform: scale(0.9); /* Increase size */
    }
    100% {
        transform: scale(0.8); /* Back to the original size */
    }
}


.icon {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.icon-content { 
    padding-bottom: 20px;
}


.custom-gap .col-md-3 {
    margin-right: 0; /* Adjust the margin as needed */
}
.custom-gap .col-md-3:last-child {
    margin-right: 0; /* Remove right margin for the last column */
}


.spacer {
    margin-left: 100px; /* Adjust as needed */
}

.height {
    width: 100%;
    margin-left: 5px;

}



/* New card styles */
/* Adjust padding and margins to reduce row height */
.card.shipment-facts {
    background-color: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
    border-radius: 12px; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
    border-bottom: 1px solid #ccc; /* Add bottom border */
}




.shipment-facts-title {
    font-family: Calibri, sans-serif;
    font-size: 3.0rem;
    margin-left: 10px !important; /* Adjust as needed */
}

.card.shipment-facts h5 {
    margin-bottom: 5px; /* Reduced margin */
    font-size: 1rem; /* Reduced font size */
}

.card.shipment-facts p {
    margin: 2px 0; /* Reduced margin */
    font-size: 0.9rem; /* Reduced font size */
}

.card.shipment-facts strong {
    font-weight: bold;
    margin-right: 3px; /* Reduced margin */
}

.card.shipment-facts .top {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px; /* Reduced padding */
    margin-bottom: 5px; /* Reduced margin */
}

.card.shipment-facts .services,
.card.shipment-facts .package-details {
    margin-top: 0px; /* Reduced margin */
}


.shipment-facts .top:nth-child(even) {
    background-color: #f8f9fa;
}

.shipment-facts .top:nth-child(odd) {
    background-color: #fff;
}

.shadowed {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}





.shipment-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.shipment-table th,
.shipment-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.shipment-table th {
    background-color: #f5f5f5;
}

.shipment-table th:first-child,
.shipment-table td:first-child {
    width: 30%;
}

.shipment-table td:first-child {
    font-weight: bold;
}

.shipment-table td {
    vertical-align: top;
}

.text-primary {
    color: #651FFF;
}




.track-history-section {
    background-color: #f5f5f5;
    padding: 50px 0;
}

    .progress-bar-vertical {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .progress-bar-vertical li {
        background-color: #ccc;
        width: 6px;
        height: 50px;
        margin-bottom: 38px;
    }

    .progress-bar-vertical li.active {
        background-color: #007bff;
    }

    .track-info {
        margin-left: 20px;
    }

    .track-info .step {
        margin-bottom: 30px;
    }

    .track-info .step h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .track-info .step p {
        font-size: 1rem;
        color: #777;
    }

    .custom-font-city {
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }



/* Add this CSS for the track history section */

/* Track History Section */
/* .track-history-section {
    background-color: #f5f5f5;
    padding: 50px 0;
}

.track-progress {
    position: relative;
    height: 400px; 
}

.progress-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #ccc;
    transform: translateX(-50%);
}

.progress-point {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    background-color: #fff;
    border-radius: 50%;
}

.progress-point:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
}

.track-info {
    padding-top: 50px; 
}

.track-point {
    margin-bottom: 40px;
}

.track-date {
    font-weight: bold;
    color: #333;
}

.track-event {
    color: #555;
}
    
 */




 .container {
    margin-bottom: 0px;
}






.track-package-section {
    background-color: #f5f5f5;
    text-align: center;
    width: 100%; /* Take full width of the parent */
    min-width: 375px; /* Set minimum width */
    margin: auto; /* Center horizontally */
}

.track-package-section h2 {
    font-size: 2rem;
    color: #333;
}

.track-package-section .form-control {
    width: 80%;
    border-radius: 25px;
    background-color: #fff;
    border-color: #ccc;
    height: 50px; /* Adjusted height */
}

.track-package-section .btn {
    border-radius: 25px;
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    height: 50px; /* Adjusted height */
    width: 80px;
}

.track-package-section .btn:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.form-control::placeholder {
    color: #999;
}




.fixed-width {
    width: 25%; /* Adjust the percentage as needed */
}


/* .stunning-footer {
    background-color: #414141; 
    padding: 30px 0;
    text-align: center;
    
}


.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-col {
    flex: 1;
    margin-bottom: 20px;
}

.footer-col h4 {
    color: #333;
    font-size: 1.1em;
    margin-bottom: 10px;
    color: white;
}

.footer-col ul {
    list-style-type: none;
    padding: 0;
}

.footer-col ul li {
    margin-bottom: 8px;
}

.footer-col ul li a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-col ul li a:hover {
    color: #333;
}

.social-links {
    margin-top: 15px;
}

.social-links a {
    display: inline-block;
    margin: 0 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.social-links a:hover {
    background-color: #555;
} */

/* Hide both footers by default */
.full-footer, .mobile-footer {
    display: none;
}

/* Show full footer on screens wider than 767px */
@media (min-width: 768px) {
    .full-footer {
        display: block;
    }
}

/* Show mobile footer on screens 767px wide or less */
@media (max-width: 767px) {
    .mobile-footer {
        display: block;
    }
}


.simple-footer {
    position: relative;
    background-color: #414141; 
    padding: 30px 20px; /* Adjust padding as needed */
    color: white;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    width: 100%; /* Take full width of the parent */
    min-width: 375px; /* Set minimum width */
    margin: auto; /* Center horizontally */
}

.footer-container {
    display: flex;
    align-items: center;
    /* max-width: 1200px; */
    margin: 0 ;
}

.copyright {
    margin-right: auto; /* Move the copyright text to the left */
    font-size: 15px;
    font-weight: 200;
    margin-left: auto;
}

.links {
    display: flex;
    margin-right: auto;
 
}

.links a {
    margin-right: 20px;
    color: #333;
    text-decoration: none;
    color: white;
}

.links a:hover {
    text-decoration: underline;
}

.social-links {
    display: flex; /* Make sure the social links are displayed as a flex container */
    margin-right: auto;
    gap: 20px;
}

.social-links a {
    display: inline-block;
    margin-left: 10px; /* Adjust spacing between social links */
    color: #fff;
    font-size: 20px;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
}



.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.back-to-top:hover {
    background-color: #555;
}